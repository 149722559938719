
.input-group-btn-sidebar-search {
  padding: 0;
  margin: 0;
  box-sizing: border-box;

  button {
	@media screen and (min-width: 1000px){
      padding: 12px 15px;
    }
  }
}