@import "../../../variables";
@import "../../../commons/mixins/mixins";

.medical-button {
  background-color: $brand-primary;
  width: 100%;
  @include browsers(transition, all, 0.3s, ease-in-out);

  &:hover {
	background-color: darken($brand-primary, 30%);
  }

}

.bottom_consulting {
  padding: 40px;
  background-color: $brand-grey-light;

  .medical-button {
	margin-top: 10px;
  }
  h3, p {
	@media screen and (max-width: 650px) {
	  text-align: center;
	}
  }
}

.modal-contact {
  @include browsers(border-radius, 32px);
}