@import "../variables";
@import "../commons/mixins/mixins";

.intro_page_image {
  display: block;
  width: 100%;
  height: 600px;
  position: relative;
  overflow: hidden;
  color: $brand-light;
  margin-top: 88px;

  .parallax_image {
	position: absolute;
	min-height: 800px;
	top: 0;
	object-fit: cover;
	margin: 0;
  width: 100%;
  }

  .intro_page_text {
	position: relative;
	top: 40%;
  }
}