@import "../../../variables";
@import "../../../commons/mixins/mixins";

.block-why-choose {
  color: $brand-grey;

  .tt02 {
	color: inherit;

	@media screen and (max-width: $mobile_max) {
	  text-align: center;
	}
  }

  p {
	color: inherit;
  }

  .intro {
	@media screen and (max-width: $mobile_max) {
	  text-align: center;
	}
  }

  .choose {
	color: inherit;

	.choose-content {
	  color: inherit;
	}

	.tt05 {
	  color: inherit;
	}

  }

  .block_details {

	& > div:nth-child(even){

	  &:after {
		content: '.';
		display: block;
		clear: both;
		color: #fff;
	  }

	}

  }
}