@import "../../variables";
@import "../../commons/mixins/mixins";

.btn-go-back {
  margin-right: 50px;
  margin-left: 0;
  padding: 10px 20px;
  @include browsers(border-radius, 30px)
}

.event_details {
  h2 {
	margin: 50px 0;

	@media screen and (max-width: 640px){
	  text-align: center;
	}
  }

  h1 {
	@media screen and (max-width: 640px){
	  float: left;
	  clear: both;
	  width:100%;
	  text-align: center;
	}
  }

  img {
	margin: 50px 0;
	width: 100%;
  }
}