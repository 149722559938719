@import "../../../variables";
@import "../../../commons/mixins/mixins";

.carousel-inner>.item>a>img, .carousel-inner>.item>img {
  width: 100%;
}


#main{
  margin-top: 88px;
  //height: 601px;
  width: 100%;
}

.slider-section .carousel-caption p {
  color: $brand-light;
  font-size: 1.5em;
}


.slider-section .carousel-caption h3 {
  color: $brand-light;
}



.slider-section {

  //height: 601px;
  overflow: hidden;

  .carousel-caption {
	a {
	  background-color: $brand-primary;
	  cursor: pointer;
	}

	a:hover {
	  background-color: darken($brand-primary, 15%);
	}

	@media screen and (max-width: $mobile_max/1.5){
	  background-color: $brand-info;
	}
  }
}

.carousel-transparent {
  background-color: $brand-primary;
  position: absolute;
  width: 60%;
  left: 0;
  top: 0;
  min-height: 601px;
  height: 100%;
  z-index: 1;
  @include browsers(opacity, 0.5);

  @media screen and (max-width: $mobile_max){
	width: 100%;
  }
}


.content-main {
  position: relative;
  overflow: hidden;
  @media screen and (max-width: $mobile_max){

  }
}