@import "../variables";
@import "../../bourbon/bourbon";

.section-footer {
  background-color: $brand-info;

  @media screen and (max-width: 640px){
    text-align: center;
  }

}

.social_footer {

  text-align: center;
  font-size: 0;
  padding-top: 30px;
}

.social_footer .icon {
  display: inline-block;
  margin-left: 5px;
  color: lighten($brand-info, 50%);
}

.social_footer .icon:first-child {
  margin-left: 0;
}

.social_footer a {
  display: table-cell;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-clip: padding-box;
  margin-right: 5px;
  border: solid 1px $brand-info;
  background-color: lighten($brand-info, 10%);
  color: $brand-info;

  &:hover {
	border: solid 1px lighten($brand-info, 30%);
	color: lighten($brand-info, 30%);
	background-color: $brand-info;
  }

}

.social_footer a i {
  font-size: 16px;
  vertical-align: middle;
}

.widget {
  p {
	font-size: 0.7em;
    line-height: 1.7em;
	color: $brand-light;
    margin-bottom: 3px;
  }
}

footer#page-footer {
  .copyright {
	color: $brand-light;
  }
  background-color: darken($brand-info, 7%);
}