.events_section {
  padding: 30px 0;

  &__header {

    margin-bottom: 30px;

    h1 {
      margin: 0;
      padding: 0;
    }
  }

  .event_item {

    img {
      margin: 0 auto;
    }

    @media screen and (max-width: 640px){
      text-align: center;
    }
  }
}