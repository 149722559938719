@import "../../variables";
@import "../../commons/mixins/mixins";
@import "../../../bourbon/bourbon";

.timeline {

  position: relative;
  padding: 2em 0;
  margin-top: 2em;
  margin-bottom: 2em;

  &:before {

	/* this is the vertical line */
	content: '';
	position: absolute;
	top: 0;
	left: 18px;
	height: 100%;
	width: 4px;
	background: $brand-grey;

  }

  @include mediaQuery(large) {
	margin: {
	  top: 3em;
	  bottom: 3em;
	}

	&::before {
	  left: 50%;
	  margin-left: -2px;
	}
  }

  &__block {

	position: relative;
	margin: 2em 0;
	@include clearfix();

	&:first-child {
	  margin-top: 0;
	}

	&:last-child {
	  margin-bottom: 0;
	}

	@include mediaQuery(large) {
	  margin: 4em 0;

	  &:first-child {
		margin-top: 0;
	  }

	  &:last-child {
		margin-bottom: 0;
	  }
	}

	&__img {

	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 40px;
	  height: 40px;
	  background-color: #fff;
	  padding: 10px;
	  @include border-bottom-radius(3px);
	  @include border-top-radius(3px);
	  border: 3px solid $brand-info;

	  img {
		display: block;
		width: 100%;
		height: 100%;
	  }

	  &__color {
		background: $brand-info;
	  }

	  @include mediaQuery(large) {
		width: 60px;
		height: 60px;
		left: 50%;
		margin-left: -30px;

		/* Force Hardware Acceleration in WebKit */
		-webkit-transform: translateZ(0);
		-webkit-backface-visibility: hidden;

	  }

	}

	&__content {
	  position: relative;
	  margin-left: 60px;
	  background: $brand-info;
	  @include border-bottom-radius(3px);
	  @include border-top-radius(3px);
	  padding: 1em;
	  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
	  @include clearfix;

	  &__title {
		color: $brand-primary;
	  }

	  &__paragraph {
		color: #ffffff;
	  }

	  &::before {
		content: '';
		position: absolute;
		top: 16px;
		right: 100%;
		height: 0;
		width: 0;
		border: 7px solid transparent;
		border-right: 7px solid $brand-info;
	  }

	  @include mediaQuery(large) {
		margin-left: 0;
		padding: 1.6em;
		width: 45%;

		&::before {
		  top: 24px;
		  left: 100%;
		  border-color: transparent;
		  border-left-color: $brand-info;
		}

		.timeline__block:nth-child(even) & {
		  float: right;

		  &::before {
			top: 24px;
			left: auto;
			right: 100%;
			border-color: transparent;
			border-right-color: $brand-info;
		  }
		}

	  }

	}

  }
}