@mixin browsers($property, $value...){
  -webkit-#{$property}: $value;
  -o-#{$property}: $value;
  -moz-#{$property}: $value;
  #{$property}: $value;
}

@mixin customProperty($property, $value){
  #{$property}: -webkit-#{$value};
  #{$property}: -o-#{$value};
  #{$property}: -moz-#{$value};
  #{$property}: #{$value};
}

// breakpoints

$S:     320px;
$M:     768px;
$L:     1170px;

// media queries

@mixin mediaQuery($canvas) {
  @if $canvas == small {
    @media only screen and (min-width: $S) { @content; }
  }
  @else if $canvas == medium {
    @media only screen and (min-width: $M) { @content; }
  }
  @else if $canvas == large {
    @media only screen and (min-width: $L) { @content; }
  }
}