@import "../../variables";
@import "../../commons/mixins/mixins";

.ebooks_filter {

  padding: 50px;
  background: $brand-grey-light;
  width: 100%;

  .ebook_filter_component {

	.ebook_filter_label p{
	  text-align: right;
	}

	p {
	  text-align: right;
	  text-transform: uppercase;

	  @media screen and (max-width: 640px){
		text-align: center;
	  }
	}

	select {
	  width: 100%;
	  background: #fff;
	}
  }
}

.ebooks_collection {
  margin: 1px;

  .ebook_item {
	box-sizing: border-box;
	border: 2px solid $brand-light;
	background-color: $brand-info;
	position: relative;
	overflow: hidden;
	height: 256px;

	.span_inside {
	  position: absolute;
	  width:100%;
	  top: 50%;
	  @include browsers(opacity, 0);
	  padding: 30px;
	  text-align: center;
	  color: $brand-light;
	  @include browsers(transition, 0.2s, ease-in-out);

	  .download_ebook {

		font-size: 1.2em;

	  }

	}

	img{
	  width: 100%;
	  height:100%;
	  @include browsers(object-fit, cover);
	  @include browsers(transition, all, 0.2s, ease-in-out);
	}

	.ebook_img_hover {
	  @include browsers(opacity, 0.3);
	  @include customProperty(transform, scale(1.2));
	}

	.ebook_span_hover {
	  @include customProperty(transform, translateY(-50%));
	  @include browsers(opacity, 1);
	}

  }
}