@import "../variables";

@import url("https://fonts.googleapis.com/css?family=Poppins:400,300,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat");
/* Border radius */
/* Transform */
/* Transition */
/* Opacity */
/* Transition-duration */
/* Transition-origin */
/* Transition-timing-function */
/* Animation */
/* Box shadow */
/* Place Holder */
/* Alpha Background Color */
/* ============================================================
	1. GENERAL
=============================================================*/
body {
  font: 300 14px/24px 'Poppins', sans-serif;
  color: #707070;
  background: #ffffff;
  /*overflow-x: hidden;*/
}

.transition,
a,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
input.input-text,
select,
input[type="submit"],
button[type="submit"] {
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}

input[type="submit"],
button[type="submit"], .button {
  background-color: #62a5a2;
  color: #ffffff;
}

input[type="submit"]:hover,
button[type="submit"]:hover,
.button:hover {
  background: #569a98;
}

input[type="submit"]:disabled,
button[type="submit"]:disabled,
.button:disabled {
  background: #f2f2f2;
  color: #b3b3b3;
  cursor: no-drop;
  border: none;
}

a {
  color: #62a5a2;
  text-decoration: none;
}

a:hover {
  color: #ff6953;
  text-decoration: none;
}

p {
  margin-top: 0;
  margin-bottom: 15px;
}
p:last-child {
  margin-bottom: 0;
}

img {
  border: medium none;
  height: auto;
  max-width: 100%;
  vertical-align: top;
}

textarea {
  height: auto;
  line-height: 1.8em;
  max-height: 150px;
}

select:focus {
  background-color: rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
}

table thead {
  text-align: left;
  background: #333333;
  color: #ffffff;
}

table thead th {
  padding: 15px 20px;
}

table tbody tr:nth-child(2n) {
  background: rgba(0, 0, 0, 0.05);
}

table tbody tr td,
table tbody tr th {
  padding: 15px 20px;
  text-align: left;
  font-weight: normal;
}

table tfoot {
  color: #ffffff;
}

table tfoot tr th,
table tfoot tr td {
  padding: 15px 20px;
}

table tfoot tr:nth-child(2n) {
  background: rgba(0, 0, 0, 0.05);
}

dl dt {
  font-weight: bold;
}

dl dd {
  margin: 0 0 20px 0;
}

figure {
  margin: 1em 0;
}

figcaption {
  margin: 10px 0;
}

article:after {
  clear: both;
  content: "";
  display: table;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', serif;
  font-weight: 400;
}

.wrapper {
  padding-left: 40px;
  padding-right: 40px;
}

@media (max-width: 1200px) {
  .wrapper {
	padding-left: 0;
	padding-right: 0;
  }
}
.notype {
  list-style: none;
  margin: 0;
  padding: 0;
}
.notype li {
  list-style: none;
}

.ui-link-white {
  color: #ffffff;
}
.ui-link-white:hover, .ui-link-white:focus {
  text-decoration: underline;
}

.tt02 {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;
  color: #333333;
  margin: 0 0 20px 0;
}

.tt05 {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: #333333;
  margin: 0 0 14px 0;
}

.tt06 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.4;
  color: #333333;
  margin: 0;
}

.color-white {
  color: #ffffff;
}

.height46 {
  height: 46px;
  padding-top: 13px;
  padding-bottom: 13px;
}

.section {
  margin-bottom: 30px;
  margin-top: 80px;
}
.section::before, .section::after {
  display: table;
  content: " ";
}
.section::after {
  clear: both;
}

.mb100 {
  margin-bottom: 100px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mbb60 {
  margin-bottom: -60px;
}

/*----------- Middle menu ------------*/
.wrapper .navbar {
  margin-bottom: 0;
  border-bottom: 0;
  border: 0;
  background: #ffffff;
  -webkit-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box;
}

.navbar.stiky {
  box-shadow: 0 1px 12px 0px rgba(51, 51, 51, 0.23);
  -webkit-box-shadow: 0 1px 12px 0px rgba(51, 51, 51, 0.23);
}

.container-nav {
  background-color: #fff;
}

.navbar-nav {
  clear: right;
}

.navbar-brand {
  margin-top: 0;
  height: auto;
}

.navbar-main .nav.menu-main > li {
  margin: 0;
}
.navbar-main .nav.menu-main > li > a {
  padding-top: 40px;
  min-height: 80px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  line-height: 22px;
  color: #333;
  background-color: transparent;
  font-weight: 500;
  text-transform: uppercase;
  -webkit-transition: 0.2s all linear;
  transition: 0.2s all linear;
  color: $brand-grey;
}
.navbar-main .nav.menu-main > li > a:hover, .navbar-main .nav.menu-main > li > a:focus, .navbar-main .nav.menu-main > li > a:active {
  color: $brand-info;
  background-color: transparent;
}

.dropdown-menu > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  font-weight: 300;
}

.navbar-toggle .icon-bar {
  background-color: $brand-info;
}

.info-toggle {
  float: right;
  margin: 35px 0 0 0;
  padding: 0;
}
.info-toggle li {
  float: left;
  padding: 0;
  list-style: none;
}
.info-toggle li a {
  display: block;
  padding: 10px;
  color: #333333;
}
.info-toggle li a:hover, .info-toggle li a:focus {
  color: #62a5a2;
}
.info-toggle .dropdown-menu {
  left: inherit;
  right: 0;
  border: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box;
  background: #62a5a2;
  -webkit-box-shadow: none;
  box-shadow: none;
  width: 250px;
  padding-bottom: 0;
  padding-top: 0;
}
.info-toggle .dropdown-menu li {
  float: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 25px;
  color: #ffffff;
}
.info-toggle .dropdown-menu li .ititle {
  opacity: .6;
  display: block;
}
.info-toggle .dropdown-menu li strong {
  opacity: .8;
  display: block;
}
.info-toggle .dropdown-menu li a {
  color: #ffffff;
  padding: 0;
}
.info-toggle .dropdown-menu li a:hover, .info-toggle .dropdown-menu li a:focus {
  background: transparent;
  color: #333333;
}

nav .fa-phone {
  float: left;
  font-size: 30px;
  margin: 12px 20px 0 0;
  width: 25px;
}

.fa-key {
  font-size: 20px;
  color: #ffffff;
  margin-right: 15px;
}

.img-flag {
  vertical-align: middle;
  margin-right: 15px;
}

.divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.extra-menu {
  overflow: hidden;
  color: #333;
}
.extra-menu .contact-phone-header {
  padding: 10px 15px;
}
.extra-menu .contact-phone-header .ititle, .extra-menu .contact-phone-header strong {
  display: block;
}
.extra-menu .fa-key, .extra-menu .fa-phone {
  color: #333;
}
.extra-menu a {
  text-transform: none !important;
}

/* Border radius */
/* Transform */
/* Transition */
/* Opacity */
/* Transition-duration */
/* Transition-origin */
/* Transition-timing-function */
/* Animation */
/* Box shadow */
/* Place Holder */
/* Alpha Background Color */
.form-control:focus {
  border-color: #000;
  -webkit-box-shadow: none;
  box-shadow: none;
}

select.form-control:focus {
  background-color: transparent;
}

.flex {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

/* ----------- section-footer ------------ */
.section-footer {
  background: #2a2a2a;
  color: #8d8e92;
  padding-top: 95px;
  padding-bottom: 70px;
}
.section-footer .widget {
  margin-bottom: 28px;
}
.section-footer .widget h3 {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 27px;
  margin-left: 0;
  margin-right: 0;
  line-height: 24px;
}
.section-footer .contact-info-footer .textwg {
  line-height: 23px;
  margin-bottom: 30px;
  padding-left: 0;
}
.section-footer .contact-info-footer p {
  line-height: 20px;
  position: relative;
  padding-left: 30px;
}
.section-footer .contact-info-footer p i.fa {
  position: absolute;
  top: 3px;
  left: 0;
  color: #8d8e92;
}
.section-footer .contact-info-footer .mailto {
  color: #8d8e92;
}
.section-footer .fa-map-marker {
  font-size: 16px;
}
.section-footer .link-twitter {
  margin-bottom: 30px;
  position: relative;
  padding-left: 65px;
}
.section-footer .link-twitter .set-twitter {
  background-color: #62a5a2;
  width: 45px;
  height: 45px;
  display: inline-block;
  line-height: 45px;
  text-align: center;
  position: absolute;
  top: 5px;
  left: 0;
}
.section-footer .link-twitter .set-twitter i.fa-twitter {
  color: #ffffff;
  font-size: 17px;
}
.section-footer .link-twitter p {
  margin-bottom: 5px;
}
.section-footer .link-twitter p a:hover {
  color: #62a5a2;
}
.section-footer .link-twitter:last-child {
  margin-bottom: 0;
}
.section-footer .list-member-ins {
  overflow: hidden;
  margin-left: -8px;
}
.section-footer .list-member-ins li {
  float: left;
  margin-left: 8px;
  margin-bottom: 8px;
}
.section-footer .list-member-ins li a, .section-footer .list-member-ins li img {
  display: block;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box;
}
.section-footer .list-member-ins li a {
  border: 1px solid transparent;
}
.section-footer .list-member-ins li a:hover, .section-footer .list-member-ins li a:focus {
  border-color: #000000;
}
.section-footer .list-member-ins li a:hover img, .section-footer .list-member-ins li a:focus img {
  opacity: 0.8;
}

.lists li {
  margin-bottom: 10px;
}
.lists li:last-child {
  margin-bottom: 0;
}
.lists a {
  color: #8d8e92;
  font-size: 14px;
  line-height: 20px;
}
.lists a .fa-caret-right {
  margin-right: 15px;
  font-size: 12px;
}
.lists a:hover {
  color: #62a5a2;
}

/* ----------- section-news ------------ */
.gray {
  background: #fafafa;
}

.grayee {
  background: #eeeeee;
}

.section-news {
  padding-top: 92px;
  padding-bottom: 100px;
}

p.intro {
  font-size: 16px;
  line-height: 30px;
  color: #7d7d7d;
  font-weight: 300;
}

.medical-button {
  background-color: #62a5a2;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box;
  font-family: 'Montserrat', serif;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  padding: 12px 0;
  color: #ffffff;
  text-shadow: 0px -1px 1px rgba(0, 0, 0, 0.25);
  border: 0;
  display: block;
}
.medical-button:hover {
  background-color: #ff6953;
  color: #ffffff;
}

.view-more {
  text-align: right;
  margin-bottom: 20px;
}

.view-more-md {
  max-width: 200px;
}

.item-box .item-box-body {
  background: #ffffff;
  border: 2px solid #eeeeee;
  border-top-width: 0;
  color: #707070;
  padding: 30px 40px;
}
.item-box .item-box-body .tt05 a {
  color: #333333;
}
.item-box .item-box-body .tt05 a:hover {
  color: #62a5a2;
}

.pic {
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: #333;
}
.pic img {
  -ms-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  -moz-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transition: 0.4s all linear;
  -o-transition: 0.4s all linear;
  -moz-transition: 0.4s all linear;
  transition: 0.4s all linear;
}
.pic:hover img {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  -moz-opacity: 0.8;
  -khtml-opacity: 0.8;
  opacity: 0.8;
  -ms-transform: scale(1.05, 1.05);
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -ms-transition-duration: 250ms;
  -webkit-transition-duration: 250ms;
  -moz-transition-duration: 250ms;
  -o-transition-duration: 250ms;
  transition-duration: 250ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.postby {
  color: #b1b1b1;
  font-size: 12px;
}
.postby .datetime {
  color: #333;
  display: inline-block;
  margin-right: 5px;
}
.postby a {
  color: #333333;
}
.postby a:hover {
  text-decoration: underline;
}

/* ----------- section-department ------------ */
.section-department {
  position: relative;
}

.our-department {
  margin-top: 90px;
  margin-bottom: 80px;
}

.group-list-department {
  overflow: hidden;
  margin-left: -10px;
  margin-right: -10px;
}
.group-list-department li {
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.group-list-department li a {
  position: relative;
  display: block;
  background-color: #eeeeee;
  padding: 45px 0;
  text-align: center;
  height: 190px;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.group-list-department li a .department-name {
  text-transform: uppercase;
  font-family: 'Montserrat', serif;
  color: #333333;
  display: inline-block;
  margin-top: 8px;
}
.group-list-department li a .fs1 {
  color: #333333;
}
.group-list-department li a img {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  z-index: 1;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.group-list-department li a:hover {
  background-color: #62a5a2;
}
.group-list-department li a:hover .department-name, .group-list-department li a:hover .fs1 {
  color: #ffffff;
}
.group-list-department li a:hover img {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  filter: alpha(opacity=30);
  -moz-opacity: 0.3;
  -khtml-opacity: 0.3;
  opacity: 0.3;
}

.fs1 {
  font-size: 65px;
}

/* ----------- section-client-say ------------ */
.section-client-say {
  padding-top: 92px;
  padding-bottom: 90px;
  background: url("../images/front/bgi-client-say.jpg") repeat center center;
}
.section-client-say .intro {
  color: #939393;
}

.client-say {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}
.client-say li {
  list-style: none;
}
.client-say li:nth-child(2n+1) .testimonial-ctn {
  border-color: #ff6953;
}
.client-say li:nth-child(2n) .testimonial-ctn {
  border-color: #62a5a2;
}

.testimonial .testimonial-ctn {
  background: #ffffff;
  border-top: 3px solid transparent;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-clip: padding-box;
  padding: 20px 30px;
  position: relative;
}
.testimonial .testimonial-ctn blockquote {
  color: #5b5b5b;
  margin: 0;
  padding: 0;
  border: 0;
  font: 300 14px/26px 'Poppins', sans-serif;
}
.testimonial .testimonial-ctn::before {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ffffff;
  display: inline-block;
  content: '';
  position: absolute;
  top: 100%;
  left: 25px;
  z-index: 1;
}
.testimonial .testimonial-meta {
  overflow: hidden;
  margin-top: 25px;
}
.testimonial .testimonial-meta .testimonial-cover {
  margin-right: 20px;
  float: left;
}
.testimonial .testimonial-meta .testimonial-author {
  overflow: hidden;
  margin-top: 10px;
}
.testimonial .testimonial-meta .testimonial-author strong {
  text-transform: uppercase;
  font-family: 'Montserrat', serif;
  color: #ffffff;
  font-weight: 400;
}
.testimonial .testimonial-meta .testimonial-author .testimonial-author-info {
  color: #8c8c8c;
  font-size: 13px;
  font-style: italic;
}

/* ----------- section-why-choose ------------ */
.section-why-choose {
  position: relative;
}

.block-why-choose {
  margin-top: 92px;
}

.primary-content .block-why-choose {
  margin-top: 12px;
}

.choose {
  margin-top: 62px;
}
.choose .choose-icon {
  float: left;
  display: inline-block;
  width: 80px;
  height: 80px;
  text-align: center;
}
.choose .choose-icon img {
  margin-top: 14px;
}
.choose .choose-content {
  margin-left: 100px;
}
.choose .choose-content .tt05 {
  margin-top: 6px;
}
.choose .service-title {
  font-size: 14px;
  text-transform: uppercase;
  color: #333333;
  margin: 0 0 10px 0;
}

.quotes {
  position: relative;
}
.quotes .head {
  background-color: #ff6853;
  color: #ffffff;
  text-align: center;
  height: 120px;
  width: 100%;
  margin-top: -120px;
  padding: 30px 0;
}
.quotes .head h4 {
  text-transform: uppercase;
  font-size: 23px;
  line-height: 27px;
  margin: 0 0 10px 0;
}
.quotes .head .hotline {
  font-size: 21px;
  margin: 0;
}

.frm-register {
  border: 2px solid #eeeeee;
  border-top-width: 0;
  background: #fafafa;
  padding: 35px 33px;
  /*for IE10*/
}
.frm-register label {
  font-weight: 300;
  margin-bottom: 4px;
  color: #333;

}
.frm-register .txt-optional{
  color: #b1b1b1;
}
.frm-register .medical-button {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  margin-top: 5px;
  -webkit-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box;
}
.frm-register textarea.form-control {
  height: 85px;
}
//.frm-register select {
//  -webkit-appearance: none;
//  -moz-appearance: none;
//  appearance: none;
//  background: url("../images/front/arrow-select.png") no-repeat 95% center #ffffff;
//}
.frm-register select::-ms-expand {
  display: none;
}

.frm-register .form-control::-moz-placeholder,
.frm-register .form-control:-ms-input-placeholder,
.frm-register .form-control::-webkit-input-placeholder {
  color: #707070;
}

/* ----------- section-gallery ------------ */
.controls {
  background-color: #eeeeee;
  height: 100px;
  margin-top: 20px;
  text-align: center;
  padding-left: 85px;
  padding-right: 85px;
  display: table;
  width: 100%;
}
.controls .tt05 {
  color: #707070;
  margin-bottom: 0;
  display: table-cell;
  vertical-align: middle;
}
.controls a {
  color: #ff6953;
}
.controls a:hover {
  text-decoration: underline;
}

.section-gallery {
  position: relative;
}
.section-gallery #owl-demo .item {
  position: relative;
}
.section-gallery #owl-demo .item .overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(98, 165, 162, 0.75);
  -webkit-opacity: 0;
  -khtml-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
  filter: alpha(opacity=0);
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.section-gallery #owl-demo .item .overlay a {
  width: 45px;
  height: 45px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  display: inline-block;
  color: #fff;
  font-size: 14px;
  position: absolute;
  margin: 0 10px;
  background-color: transparent;
  border: 2px solid #ffffff;
  top: calc(50% - 23px);
  text-align: center;
  line-height: 40px;
}
.section-gallery #owl-demo .item .overlay a.readmore {
  left: -40px;
  -webkit-transition: all 0.15s ease-in-out 0s;
  -moz-transition: all 0.15s ease-in-out 0s;
  -ms-transition: all 0.15s ease-in-out 0s;
  -o-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}
.section-gallery #owl-demo .item .overlay a.quickview {
  right: -40px;
  -webkit-transition: all 0.15s ease-in-out 0s;
  -moz-transition: all 0.15s ease-in-out 0s;
  -ms-transition: all 0.15s ease-in-out 0s;
  -o-transition: all 0.15s ease-in-out 0s;
  transition: all 0.15s ease-in-out 0s;
}
.section-gallery #owl-demo .item:hover .overlay {
  -webkit-opacity: 1;
  -khtml-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(opacity=100);
  filter: alpha(opacity=100);
}
.section-gallery #owl-demo .item:hover .overlay .readmore {
  left: calc(50% - 60px);
}
.section-gallery #owl-demo .item:hover .overlay .quickview {
  right: calc(50% - 60px);
}
.section-gallery .owl-pagination {
  display: none;
}
.section-gallery .owl-theme .owl-controls {
  margin-top: 0;
}
.section-gallery .owl-theme .owl-controls .owl-buttons div {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box;
  position: absolute;
  bottom: 0;
  margin-bottom: -95px;
}
.section-gallery .owl-theme .owl-controls .owl-buttons div.owl-prev {
  background: url(../images/front/sprite.png) no-repeat 0 -146px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF, endColorstr=#00FFFFFF);
  margin-left: 25px;
  left: 0;
}
.section-gallery .owl-theme .owl-controls .owl-buttons div.owl-prev:hover {
  background: url(../images/front/sprite.png) no-repeat 0 -203px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF, endColorstr=#00FFFFFF);
}
.section-gallery .owl-theme .owl-controls .owl-buttons div.owl-next {
  background: url(../images/front/sprite.png) no-repeat -59px -146px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF, endColorstr=#00FFFFFF);
  margin-right: 25px;
  right: 0;
}
.section-gallery .owl-theme .owl-controls .owl-buttons div.owl-next:hover {
  background: url(../images/front/sprite.png) no-repeat -59px -203px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF, endColorstr=#00FFFFFF);
}

/* ----------- slider-section ------------ */
.no-padding {
  padding: 0;
}

.slider-section {
  position: relative;
  display: block;
  width: 100%;
}

.slider-section .item::before,
.slider-section .item::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.slider-section .carousel-caption {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  text-align: left;
  text-shadow: none;
  bottom: 0;
}

.slider-section .col-md-7.ow-pull-right {
  position: relative;
  right: -38px;
}

.slider-section .col-md-7.ow-pull-left {
  position: relative;
  left: -14px;
}

.slider-section .col-md-5.ow-pull-right {
  position: relative;
  right: -38px;
}

.slider-section .carousel-caption h3 {
  font-size: 52px;
  font-weight: 700;
  line-height: 1.4;
  margin: 0 0 15px;
  word-wrap: break-word;
  color: #2d2d2d;
  animation-delay: 2s;
}

.slider-section .carousel-caption p {
  font-size: 16px;
  color: #1d1d1d;
  line-height: 28px;
  font-family: 'Montserrat', serif;
}

.slider-section .carousel-caption a {
  background: #ff6953;
  color: #fff;
  font-family: 'Montserrat', serif;
  display: inline-block;
  margin-top: 22px;
  padding: 13px 24px;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  position: relative;
  word-wrap: break-word;
  z-index: 1;
  animation-delay: 3s;

}

.slider-section .carousel-control {
  width: 0;
  margin: 0 10px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 999;
  height: 90px;
  width: 35px;
  opacity: 1;
  line-height: 90px;
  text-shadow: none;
  background: transparent;
  transition: all 1s ease 0s;
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
}

.slider-section .left.carousel-control {
  left: 28px;
}

.slider-section .carousel-control .fa {
  color: #ffffff;
  font-size: 60px;
}
.slider-section .carousel-control:hover .fa {
  color: #ff6853;
}

/* ----------- About Us ------------ */
.pink {
  color: #ff6853;
}

/* [ Responsive ] */
/* ========================================================================== */
/* ========================================================================== */
/* -- min-width: 1200 to max-width: 1365 */
@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  /* -- Slider Section */
  .slider-section .carousel-caption {
	top: 46%;
  }

  .slider-section .carousel-control {
	top: 45%;
  }
}
/* -- min-width: 992 to max-width: 1199 */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* -- Slider Section */
  .slider-section .carousel-caption {
	top: 48%;
  }

  .slider-section .carousel-caption h4 {
	font-size: 18px;
	margin: 0;
  }

  .slider-section .carousel-caption h3 {
	font-size: 24px;
	margin: 0;
  }

  .slider-section .carousel-caption p {
	font-size: 15px;
	line-height: 24px;
  }

  .slider-section .carousel-caption a {
	margin-top: 5px;
	padding: 8px 28px;
	font-size: 14px;
  }

  .slider-section .carousel-control {
	/*top: auto;*/
	height: 50px;
	width: 25px;
	line-height: 50px;
  }
}
/* -- max-width: 991 */
@media (max-width: 991px) {
  /* -- Slider Section */
  .slider-section {
	margin-top: 0;
  }

  .slider-section .carousel-caption {
	top: 36%;
  }

  .slider-section .carousel-caption h4 {
	font-size: 18px;
	margin: 0;
  }

  .slider-section .carousel-caption h3 {
	font-size: 24px;
	margin: 0;
  }

  .slider-section .carousel-caption p {
	font-size: 15px;
	line-height: 24px;
  }

  .slider-section .carousel-caption a {
	margin-top: 5px;
	padding: 8px 28px;
	font-size: 14px;
  }

  .slider-section .carousel-control {
	height: 40px;
	line-height: 40px;
	width: 22px;
	margin: 0;
  }
}
/* -- max-width: 767 */
@media (max-width: 767px) {
  /* -- Slider Section */
  .slider-section .carousel-caption {
	text-align: center;
  }

  .slider-section .carousel-caption h4 {
	font-size: 16px;
	line-height: 22px;
  }

  .slider-section .carousel-caption h3 {
	font-size: 19px;
	line-height: 32px;
  }

  .slider-section .carousel-caption p {
	font-size: 14px;
  }

  .slider-section .carousel-caption a {
	margin-top: 0;
	padding: 6px 20px;
  }

  .slider-section .left.carousel-control {
	left: 20px;
  }

  .slider-section .col-md-7.ow-pull-right {
	right: 0;
  }

  .slider-section .col-md-7.ow-pull-left {
	left: 0;
  }

  .slider-section .col-md-5.ow-pull-right {
	right: 0;
  }

}
/* --  max-width: 639 */
@media (max-width: 639px) {
  /* -- Slider Section */
  .slider-section .carousel-caption {
	top: 28%;
  }

  .slider-section .carousel-caption h4 {
	font-size: 14px;
	line-height: 18px;
  }

  .slider-section .carousel-caption h3 {
	font-size: 15px;
	line-height: 23px;
  }

  .slider-section .carousel-caption p {
	font-size: 14px;
  }

  .slider-section .carousel-caption a {
	padding: 3px 15px;
  }
}
/* --  max-width: 479 */
@media (max-width: 479px) {
  /* -- Slider Section */
  .slider-section .col-xs-9 {
	padding-left: 0;
  }

  .slider-section .carousel-caption {
	position: relative;
	transform: none;
	background-color: #eee;
	text-align: center;
	-moz-transform: none;
	-webkit-transform: none;
	transform: none;
	top: 0;
  }

  .slider-section .carousel-caption h4 {
	font-size: 12px;
	line-height: 15px;
  }

  .slider-section .carousel-caption h3 {
	font-size: 13px;
	line-height: 20px;
  }

  .slider-section .col-md-7.ow-pull-right {
	right: 0;
  }

  .slider-section .col-md-7.ow-pull-left {
	left: 0;
  }

  .slider-section .col-md-5.ow-pull-right {
	right: 0;
  }
}
.ui-link-gray33 {
  color: #333333;
}
.ui-link-gray33:hover, .ui-link-gray33:focus {
  color: #000;
}

/* Border radius */
/* Transform */
/* Transition */
/* Opacity */
/* Transition-duration */
/* Transition-origin */
/* Transition-timing-function */
/* Animation */
/* Box shadow */
/* Place Holder */
/* Alpha Background Color */
.primary-content {
  margin-bottom: 100px;
}

/* ----------- section-subbanner ------------ */
.section-subbanner {
  background: url("../images/subbanner.jpg") no-repeat fixed center center;
  webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-bottom: 30px;
  text-align: center;
  margin-bottom: 80px;
}
.section-subbanner .caption {
  margin-top: 180px;
  color: #fff;
  font-size: 45px;
  font-family: 'Montserrat', serif;
  font-weight: 700;
  line-height: 1.4;
}
.section-subbanner .breadcrumb {
  padding: 8px 0;
  margin-top: 140px;
  list-style: none;
  background-color: transparent;
  margin-left: 0;
  margin-bottom: 0;
}
.section-subbanner .breadcrumb a {
  color: #ffffff;
}
.section-subbanner .breadcrumb > .active {
  color: #b6b6b7;
}

.stats {
  padding-top: 90px;
  padding-bottom: 95px;
}
.stats .box-stat h2 {
  font-size: 65px;
  line-height: 1;
  color: #ff6853;
  margin: 0 0 15px 0;
}
.stats .box-stat .box-stat-title {
  font-size: 18px;
  line-height: 1.4;
  color: #333333;
  font-family: 'Montserrat', serif;
  margin-bottom: 16px;
}
.stats .box-stat .desc-short {
  color: #707070;
}

.team {
  padding-top: 92px;
  padding-bottom: 95px;
}
.team .box-team {
  margin-top: 30px;
}
.team .box-team .tt06 {
  margin: 22px 0 5px 0;
}
.team .box-team p {
  font-style: italic;
  color: #707070;
}

.purchase {
  padding-top: 82px;
  padding-bottom: 89px;
  background: url("../images/bgi-purchase.jpg") no-repeat center center;
  color: #ffffff;
}
.purchase h3 {
  font-size: 32px;
  line-height: 1.4;
  font-family: 'Montserrat', serif;
  margin: 0 0 10px 0;
}
.purchase p {
  font-size: 18px;
}

.medical-btn {
  border: 2px solid #ffffff;
  font-family: 'Montserrat', serif;
  text-transform: uppercase;
  padding: 13px 26px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  background-clip: padding-box;
  color: #ffffff;
  margin-top: 10px;
  display: inline-block;
}
.medical-btn:hover {
  color: #62a5a2;
  background: #ffffff;
  text-decoration: none;
}

.faqs .panel {
  border-width: 2px;
  -webkit-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box;
  margin-bottom: 10px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.faqs .panel-default {
  border-color: #eeeeee;
}
.faqs .panel-default > .panel-heading {
  background-color: transparent;
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.faqs .panel-default > .panel-heading h4 {
  font-size: 14px;
  line-height: 26px;
}
.faqs .panel-default > .panel-heading a::before {
  padding: 0 20px 0 0;
  color: #ff6953;
  content: "\f056";
  font-family: FontAwesome;
  font-size: 14px;
  float: left;
}
.faqs .panel-default > .panel-heading a.collapsed::before {
  content: "\f055";
}

.box-article img {
  margin: 0 auto;
}

.faq-testimonial {
  padding-top: 90px;
}
.faq-testimonial .testimonial .testimonial-ctn {
  border-color: #ff6953;
  background-color: #fafafa;
}
.faq-testimonial .testimonial .testimonial-ctn::before {
  border-top-color: #fafafa;
}

.faq-testimonial .testimonial .testimonial-meta .testimonial-author strong {
  color: #333333;
}

.faq-testimonial .testimonial .testimonial-meta .testimonial-author .testimonial-author-info {
  color: rgba(51, 51, 51, 0.35);
}

/* Border radius */
/* Transform */
/* Transition */
/* Opacity */
/* Transition-duration */
/* Transition-origin */
/* Transition-timing-function */
/* Animation */
/* Box shadow */
/* Place Holder */
/* Alpha Background Color */
.no-padding {
  padding: 0;
}

/* ----------- gallery ------------ */
.gallery-list {
  /*display: inline-block;
  width: 100%;*/
  margin-bottom: 0;
}
.gallery-list li {
  display: inline-block;
  margin-bottom: 60px;
}
.gallery-list li.gallery-list-image {
  margin-bottom: 30px;
}
.gallery-list .image-block {
  position: relative;
}
.gallery-list .image-block img {
  max-width: 100%;
  height: auto;
}
.gallery-list .image-block::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: inline-block;
  background-color: rgba(98, 165, 162, 0.75);
  opacity: 0;
  transition: all 1s ease 0s;
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
}
.gallery-list .image-block .zoom {
  position: absolute;
  top: 0;
  left: 50%;
  text-align: center;
  height: 45px;
  width: 45px;
  color: #fff;
  font-size: 14px;
  border: 2px solid #ffffff;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  background-clip: padding-box;
  line-height: 43px;
  text-decoration: none;
  transition: all 1s ease 0s;
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  opacity: 0;
  -moz-transform: translate(-50%, -50%) scaleY(-1);
  -o-transform: translate(-50%, -50%) scaleY(-1);
  -webkit-transform: translate(-50%, -50%) scaleY(-1);
  transform: translate(-50%, -50%) scaleY(-1);
}
.gallery-list .image-block:hover::before {
  opacity: 1;
}
.gallery-list .image-block:hover .zoom {
  top: 50%;
  opacity: 1;
}
.gallery-list .gallery-head .title {
  font-size: 14px;
  text-transform: uppercase;
  margin: 20px 0 5px 0;
  color: #333333;
}
.gallery-list .gallery-head .gallery-head-desc {
  color: #a8a8a8;
  font-size: 12px;
}
.gallery-list .gallery-head .gallery-head-desc .tag {
  display: inline-block;
  margin-left: 8.10811%;
}
.gallery-list .gallery-head .gallery-head-desc .tag i, .gallery-list .gallery-head .gallery-head-desc .calendar i {
  margin-right: 2px;
}

/* ----------- pagination ------------ */
.pagination {
  margin-bottom: 0;
  margin-top: 0;
}

.pagination-lg > li:last-child > a, .pagination-lg > li:last-child > span {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-lg > li:first-child > a, .pagination-lg > li:first-child > span {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-lg > li > a, .pagination-lg > li > span {
  color: #333333;
  font-size: 14px;
  font-family: 'Montserrat', serif;
}

.pagination > li > a, .pagination > li > span {
  margin: 0 2px;
  border-color: #dfdfdf;
}

/* ----------- blog ------------ */
.blog .item-box, .grid-blog {
  margin-bottom: 30px;
}

.spacing-bottom {
  line-height: 0;
}

/* Border radius */
/* Transform */
/* Transition */
/* Opacity */
/* Transition-duration */
/* Transition-origin */
/* Transition-timing-function */
/* Animation */
/* Box shadow */
/* Place Holder */
/* Alpha Background Color */
/* ----------- blog-columns ------------ */
.block-search .input-append {
  display: block;
  margin-bottom: 70px;
  white-space: nowrap;
  border: 1px solid #e6e6e6;
  position: relative;
  padding-right: 50px;
}
.block-search .input-append input[type="text"] {
  background-color: #ffffff;
  border-color: transparent;
  font-size: 12px;
  color: #333;
  border-radius: 0;
  box-shadow: 0 0;
}
.block-search .input-append .btn {
  background-color: #ffffff;
  background-image: none;
  border-color: transparent;
  color: #333333;
  text-shadow: 0 0 0;
  box-shadow: 0 0 0;
  position: absolute;
  right: 5px;
  top: 50%;
  -moz-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.block-search .input-append .btn i {
  font-size: 14px;
}

.weare-content .choose {
  margin-top: 0;
}

/* ----------- categories ------------ */
.widget .widget-title {
  margin: 20px 0;
}
.widget .widget-title h4 {
  font-size: 16px;
  text-transform: uppercase;
  line-height: 1.3;
  color: #333333;
  margin: 0;
}
.widget .media {
  padding-top: 15px;
  padding-bottom: 25px;
  margin-top: 0;
}

.categories .category-nav {
  padding-bottom: 20px;
}
.categories .category-nav li {
  border-bottom: 1px solid #e6e6e6;
}
.categories .category-nav li a {
  color: #333;
  display: block;
  padding: 13px 0;
}

.categories .category-nav li a::before {
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #666666;
  display: inline-block;
  content: '';
  margin-right: 10px;
}
.categories .category-nav li a:hover {
  color: #62a5a2;
  background-color: #F8F8F8;
}
.categories .category-nav li span {
  display: inline;
  color: #999999;
  font-weight: 300;
}
.categories .category-nav li:last-child {
  border-bottom-width: 0;
}

.recent-post .media-object {
  width: 100px;
  height: 85px;
}
.recent-post .widget-body {
  margin-bottom: 40px;
}

.media .media-left {
  padding-right: 20px;
}
.media .media-body .media-heading {
  font-size: 14px;
  margin: 0 0 12px 0;
  line-height: 1.3;
}
.media .media-body .media-heading a {
  color: #333333;
}
.media .postago {
  color: #b1b1b1;
  font-size: 12px;
}

.color-orange {
  color: #ff6953;
}

.color-333 {
  color: #333;
}

.color-a8 {
  color: #a8a8a8;
}

.list-latest {
  padding-bottom: 20px;
}
.list-latest li {
  padding-top: 12px;
  padding-bottom: 12px;
  color: #333333;
}
.list-latest li .list-latest-desc {
  margin-bottom: 9px;
}

.list-inline {
  padding-top: 15px;
}
.list-inline li {
  margin-bottom: 5px;
  margin-right: 4px;
  padding-right: 0;
}
.list-inline li a {
  color: #333333;
}
.list-inline li a:hover {
  color: #62a5a2;
}

/* ----------- blogpost ------------ */
.blogpost .type-post {
  margin-bottom: 45px;
  border-bottom: 1px solid #e5e5e5;
}
.blogpost .type-post .entry-title {
  font-size: 25px;
  line-height: 1.3;
  text-transform: uppercase;
  margin: 0 0 30px 0;
  color: #333333;
}
.blogpost .type-post .entry-cover {
  margin-bottom: 30px;
}
.blogpost .type-post .entry-content {
  margin-bottom: 20px;
}
.blogpost .type-post .entry-content .tt06 {
  margin-bottom: 10px;
}
.blogpost .type-post .entry-meta {
  display: inline-block;
  width: 100%;
  line-height: 26px;
  margin-top: 28px;
  margin-bottom: 40px;
}
.blogpost .type-post .entry-meta label {
  margin-bottom: 0;
  font-size: 16px;
  font-family: 'Montserrat', serif;
  padding-right: 20px;
  font-weight: 400;
}
.blogpost .type-post .entry-meta .post-share {
  display: inline-block;
  transition: all 1s ease 0s;
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
  margin-right: 0;
}

.post-share ul {
  padding-left: 0;
  display: inline-block;
  margin-bottom: 0;
  margin-left: 0;
  transition: all 1s ease 0s;
  -webkit-transition: all 1s ease 0s;
  -moz-transition: all 1s ease 0s;
  -o-transition: all 1s ease 0s;
}
.post-share ul li {
  display: inline-block;
}
.post-share ul li a {
  height: auto;
  width: auto;
  border: none;
  margin-right: 14px;
}
.post-share ul li a .fa {
  font-size: 18px;
  color: #999;
}
.post-share ul li a:hover .fa {
  color: #ff6953;
}

/* ----------- post-comments ------------ */
.sect-header {
  margin-top: 40px;
}
.sect-header h3 {
  text-transform: uppercase;
  font-size: 20px;
  color: #333333;
  margin: 0;
}

.post-comments .media {
  margin-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e5e5e5;
}
.post-comments .media-object {
  -webkit-border-radius: 100%;
  border-radius: 100%;
  background-clip: padding-box;
}
.post-comments .media-heading {
  text-transform: uppercase;
  color: #333333;
}
.post-comments .media-heading span {
  text-transform: lowercase;
  color: #a8a8a8;
  display: inline-block;
  margin-left: 5px;
  font-weight: 300;
}

.medical-button-xs {
  display: inline-block;
  font-size: 12px;
  padding: 5px 15px;
}

.form-control {
  border-color: #e5e5e5;
  font-family: 'Poppins', sans-serif;
  -webkit-border-radius: 0;
  border-radius: 0;
  background-clip: padding-box;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.comment-form .frm {
  margin-top: 40px;
}
.comment-form .medical-button {
  font-size: 14px;
  padding: 12px 22px;
  border: 0;
}

@media (min-width: 1200px) {
  .form-control {
	padding-top: 10px;
	padding-bottom: 10px;
	height: 45px;
	font-size: 14px;
  }

  textarea.form-control {
	height: auto;
  }
}
.inner-addon {
  position: relative;
}

/* style icon */
.inner-addon .fa {
  position: absolute;
  pointer-events: none;
  font-size: 14px;
  color: #b1b1b1;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.inner-addon .fa.fa-comment {
  top: 20px;
}

.left-addon .fa {
  left: 15px;
}

.left-addon input, .left-addon textarea {
  padding-left: 40px;
}

@media screen and (max-width: 1199px) {
  .inner-addon .fa.fa-comment {
	top: 15px;
  }
}
a.color-gray {
  color: #333333;
}
a.color-gray:hover {
  color: #ff6953;
}

.entry-author {
  padding-bottom: 5px;
}
.entry-author .media-heading {
  color: #333333;
  text-transform: uppercase;
}

/* Border radius */
/* Transform */
/* Transition */
/* Opacity */
/* Transition-duration */
/* Transition-origin */
/* Transition-timing-function */
/* Animation */
/* Box shadow */
/* Place Holder */
/* Alpha Background Color */
/* ----------- services ------------ */
.weare-content {
  margin-top: 30px;
}
.weare-content .fs1 {
  color: #333333;
}
.weare-content .choose .choose-icon {
  margin-top: 6px;
}

.grid-service {
  margin-bottom: -60px;
}

.view-detail {
  color: #ff6953;
}
.view-detail:hover {
  color: #62a5a2;
}

.service-single .quotes .head {
  margin-top: 0;
}
.service-single .group-list-department {
  margin-bottom: 20px;
}
.service-single .group-list-department li {
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}
.service-single .group-list-department a {
  height: 180px;
  padding: 40px 0;
}

.service-detail {
  padding-left: 40px;
}

.block-text ol {
  margin-bottom: 30px;
}
.block-text ol li {
  margin-bottom: 10px;
  float: left;
  width: 50%;
}
.block-text ol li .fa {
  color: #62a5a2;
}

.service-grid-item {
  padding: 45px 30px;
  margin-bottom: 30px;
}
.service-grid-item .choose, .service-grid-item .weare-content {
  margin-top: 0;
}

/* ----------- contactus ------------ */
.display-block .medical-button {
  display: block;
  width: 100%;
  text-align: center;
}

.contact-info {
  padding-bottom: 60px;
  padding-top: 20px;
}
.contact-info h3 {
  text-transform: uppercase;
  color: #ff6953;
  font-size: 16px;
  margin: 0 0 15px 0;
}
.contact-info .fa {
  font-size: 14px;
  color: #333333;
  width: 15px;
  text-align: center;
  margin-right: 15px;
}
.contact-info:last-child {
  padding-bottom: 0;
}
.contact-info a:hover {
  color: #62a5a2;
}

.color-70 {
  color: #707070;
}

#map {
  height: 400px;
  width: 100%;
}

.box-intro {
  margin-bottom: -30px;
}

#page-footer {
  padding-top: 22px;
  padding-bottom: 22px;
}

.footer .fa-heart {
  color: #ff6853;
}

.copyright {
  //text-align: center;
  color: #333333;
}

.contact-info-footer .fa {
  margin-right: 15px;
}

@media (min-width: 1366px) {
  .department-bg-img {
	width: 38%;
  }
}
@media (min-width: 1200px) {
  .navbar-brand {
	padding-top: 22px;
	padding-bottom: 22px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-brand {
	max-width: 150px;
  }

  .navbar-main .nav.menu-main > li > a {
	padding-top: 30px;
  }

  .nav > li > a {
	padding-left: 13px;
	padding-right: 13px;
  }

  .info-toggle {
	margin-top: 20px;
  }
}
@media (min-width: 992px) {
  /* navbar-main */
  .navbar-main.stiky {
	position: fixed;
	right: 0;
	left: 0;
	z-index: 1030;
  }
  .navbar-main.stiky .navbar-nav > li > a {
	padding-top: 20px;
	min-height: 60px;
  }
  .navbar-main.stiky .navbar-brand {
	padding-top: 8px;
  }
  .navbar-main.stiky .info-toggle {
	margin-top: 10px;
  }

  .navbar-main.stiky .navbar-brand > img {
	width: 60%;
  }

  .info-toggle, .middle-menu {
	float: right;
  }

  .department-bg-img {
	position: absolute;
	/*top: 0;*/
	right: 0;
	width: 36%;
	bottom: 0;
	display: inline-block;
  }

  .navbar-nav > li.divider, .navbar-nav > li.extra-menu {
	display: none;
  }

  .purchase-now {
	text-align: right;
  }

  #page-footer .footer {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
  }
}
@media (max-width: 991px) {
  .navbar-main.stiky {
	position: relative;
  }

  .info-toggle {
	display: none;
  }

  .navbar-brand {
	max-width: 146px;
	padding-top: 10px;
	padding-bottom: 10px;
  }

  /* menu nav-toogle */
  .navbar-header {
	float: none;
  }

  .navbar-toggle {
	display: block;
	margin-top: 19px;
  }

  .navbar-collapse {
	border-top: 1px solid transparent;
	box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }

  .navbar-fixed-top {
	top: 0;
	border-width: 0 0 1px;
  }

  .navbar-collapse.collapse {
	display: none !important;
  }

  .navbar-nav {
	float: none !important;
	margin-top: 7.5px;
  }

  .navbar-nav > li {
	float: none;
  }

  .navbar-nav > li > a {
	padding-left: 10px;
	padding-right: 10px;
  }

  .collapse.in {
	display: block !important;
  }

  .navbar-main .nav.menu-main > li > a {
	padding-top: 10px;
	min-height: 20px;
  }

  .navbar-nav .open .dropdown-menu {
	position: static;
	float: none;
	width: auto;
	margin-top: 0;
	background-color: transparent;
	border: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
  }

  /* end menu */
  .quotes .head {
	margin-top: 0;
  }

  .frm-register select {
	background-position: 98% center;
  }

  .item-box .item-box-body {
	padding: 20px 12px;
  }

  .section-why-choose .quotes {
	margin-top: 60px;
  }

  /* footer */
  .footer .social-links {
	text-align: center;
	margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .gallery-list .image-block img, .pic img {
	width: 100%;
  }

  .item-box, .client-say li {
	margin-bottom: 30px;
  }

  .service-single .quotes {
	margin-bottom: 30px;
  }

  .service-detail {
	padding-left: 0;
  }

  .row.area-blog, .blog.blogpost {
	padding-top: 30px;
  }

  .contact-info {
	padding-bottom: 0;
	padding-top: 30px;
  }
  .quotes .head h4{
	font-size: 20px;
  }
  .controls .tt05{
	font-size: 14px;
	line-height: 16px;
  }
  .section-footer .list-member-ins{
	margin: 0px;
  }
  .section-footer .list-member-ins li{
	margin: 0px;
	padding: 0px 8px;
	margin-bottom: 8px;
	width: 33.3333333%;
  }
  .slider-section .carousel-control{
	display: none;
  }
  .section-subbanner .caption{

	margin-top: 60px;
	font-size: 35px;
  }
  .section-subbanner .breadcrumb{

	margin-top: 60px;
  }
  .tt02{
	font-size: 30px;
  }
  .section-subbanner{
	margin-bottom: 40px;
  }
  .block-search{
	margin-top: 40px;
  }
  .section-footer{
	padding: 40px 0px;
  }
}
@media (max-width: 480px) {
  .stats .col-stat {
	width: 100%;
	margin-bottom: 15px;
  }

  .stats .box-stat h2 {
	font-size: 40px;
  }

  .purchase h3 {
	line-height: 1.1;
  }

  .medical-btn {
	padding: 9px;
  }

  .intro-team-member {
	width: 100%;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .purchase h3 {
	font-size: 28px;
  }

  .purchase p {
	font-size: 15px;
  }
}
