@import "../../variables";
@import "../../commons/mixins/mixins";

._media {
  .img-media-full {
	width: 100%;
	clear: both;
	overflow: hidden;

	.img-media {
	  object-fit: cover;
	  object-position: 50% 50%;
	  width: 100%;

	  @include browsers(transition, 0.5s, all, ease-in-out);

	  &:hover {
		@include browsers(transform, scale(1.2));
	  }

	}
  }
}

._blog {
  h3 {
	color: $brand-info;
  }

  a {
	color: $brand-info;
  }

  .btn {
	background-color: $brand-info;
	color: $brand-light;

	&:hover {
	  background-color: darken($brand-info, 30%);
	}
  }
}

.blog_section {
  @extend ._media;
  @extend ._blog;
}

.blog_post_section {
  @extend ._blog;

  .post {
	img {
	  object-fit: cover;
	}

	margin: 50px 0;
  }

}