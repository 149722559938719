@import "../variables";
@import "../commons/mixins/mixins";

body .navbar {
	background-color: $brand-light !important;

  	.navbar-brand img {
	  @include browsers(transition, 0.2s, all, ease-in-out);
	}

}

.middle-menu {
  text-align: center;
}

#navigation {
  text-transform: capitalize;
}


// custom
.navbar-main .nav.menu-main > li > a:hover {
  font-weight: bold;
}