.slick-prev:before, .slick-next:before {
  font-family: 'FontAwesome';
  font-weight: bold;
  font-size: 40px;
  line-height: 1;
  opacity: .75;
  color: white;
  -webkit-font-smoothing: antialiased;

}

.slick-prev, .slick-next {
  z-index: 2;
}

.slick-prev {
  left: 25px;
  height: 40px;

  &:before {
    content: "\f104";
  }
}

.slick-next {
  right: 25px;
  height: 40px;

  &:before {
    content: "\f105"
  }
}

.slick-slide img {
  width: 100%;
}