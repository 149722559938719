@import "../../../variables";
@import "../../../commons/mixins/mixins";

.our-department {
  color: $brand-grey;

  .tt02 {
	color: inherit;
  }

  p {
	color: inherit;
  }
}

.group-list-department {
  li a {
	overflow: hidden;
	img {
	  @include browsers(opacity, 1);
	  object-fit: cover;

	  &:hover {
		@include customProperty(transform, scale(1.2));
	  }
	}
  }

  @media screen and (max-width: $mobile_max) {
	text-align: center;
  }

}