@import "../../../variables";
@import "../../../commons/mixins/mixins";

.frm-register {
  background-color: $brand-info;
  border: none;

  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;

  input, select, textarea, .btn {
	border-radius: 10px;
  }

  label {
	color: $brand-light;
  }
}

.quotes .head {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: $brand-info;
  padding: 25px;

  h4 {
	font-size: 1.2em;
  }

}

.before_icon_consulting {

  text-align: left;
  margin-left: 10px;
  font-size: 1em;

  p {
	font-size: 0.7em;
	margin-top: -5px;
	padding: 0;
	line-height: 1.5em;
  }

  &:before {
	content: "";
	display: block;
	float: left;
	background: url("/images/chat_icon.png") no-repeat center center;
	width: 48px;
	height: 48px;
	margin-right: 10px;
  }

}